.datatable-search-data {
    position: relative;
}

span.datatable-search-icon {
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;
    margin: auto;
    height: 16px;
}

.datatable-search-data input {
    border-radius: 8px;
}